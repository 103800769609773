import {
  GetAppointmentsInfosRequest,
  GetAppointmentsInfosResponse,
  GetAppointmentsInfoUrl,
} from '@global/utils/api/appointment.controller.model';
import { NativeConfigWebviewExtraDataTelehealthInfo } from '@global/utils/native/native-config';
import { CustomWebviewMessageForTeleatendimentoInicio } from '@global/utils/native/native-custom-webview-message';
import { shuffleWithWeightsAndFallback } from '@global/utils/array/shuffle';
import { swapArrayElements } from '@global/utils/array/swap';
import { GradientBackground } from '@lp-root/src/components/atm.background/background.styled';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { IAdvertisementSectionProps } from '@lp-root/src/modules/telehealth/components/advertisement-section.component';
import { HcpInfo, HcpInicioProfileQueryOutput, mapToHcpCardInfoInput } from '@lp-root/src/modules/telehealth/profile/telehealth.utils';
import { HcpCardInfo, StartPageWrapper } from '@lp-root/src/modules/telehealth/start-page/start-page-wrapper.component';
import { PageProps } from '@lp-root/src/utils/local-types';
import { NativeHelper } from '@web/data/native.helper';
import { useGetUsingMessenger } from '@web/data/use-get-using-messenger.hook';
import { graphql } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';

const InicioPage: React.FunctionComponent<PageProps<unknown, HcpInicioProfileQueryOutput>> = (props) => {
  const advertisement: IAdvertisementSectionProps = useInicioPageAdvertisement();
  const { health_carer_telehealth } = props.data?.hasura;

  const { data, error, loading } = useGetUsingMessenger<GetAppointmentsInfosRequest, GetAppointmentsInfosResponse>({
    url: GetAppointmentsInfoUrl,
    params: { user_id: NativeHelper.userId },
  });

  const handleGetMoreAppointmentsClick = () => {
    const msg: CustomWebviewMessageForTeleatendimentoInicio = {
      type: 'custom',
      id: 'more_info_max_appointment_reached',
    };
    NativeHelper.postMessage(msg);
  };

  const handleMoreInfoAboutNextAppointmentClick = () => {
    const msg: CustomWebviewMessageForTeleatendimentoInicio = {
      type: 'custom',
      id: 'more_info_appointment',
    };
    NativeHelper.postMessage(msg);
  };

  const handleMoreInfoAboutSchedulingClick = () => {
    const msg: CustomWebviewMessageForTeleatendimentoInicio = {
      type: 'custom',
      id: 'more_info_telehealth',
    };
    NativeHelper.postMessage(msg);
  };

  const handleScheduleClick = (hcpId: string, hcpName: string) => {
    const msg: CustomWebviewMessageForTeleatendimentoInicio = {
      type: 'custom',
      id: 'schedule',
      data: { hcpId, hcpName },
    };
    NativeHelper.postMessage(msg);
  };

  const handleViewProfileClick = (hcpId: string, hcpName: string, hasDavId: boolean) => {
    const msg: CustomWebviewMessageForTeleatendimentoInicio = {
      type: 'custom',
      id: 'show_hcp_profile',
      data: { hcpId, hcpName, hasDavId },
    };
    NativeHelper.postMessage(msg);
  };

  const shuffleHcpList = () => {
    const randomOrderedHcpList = shuffleWithWeightsAndFallback<HcpInfo>(
      health_carer_telehealth,
      health_carer_telehealth.map((hcp) => hcp.sort_weight)
    );
    const randomOrderedHcpCardList = mapToHcpCardInfoInput(randomOrderedHcpList);
    const firstHcpId = data?.last_appointment?.health_carer_id;
    const firstHcpIndex = randomOrderedHcpCardList.findIndex((hcp) => hcp.healthCarerId === firstHcpId);
    if (firstHcpIndex > 0) {
      return swapArrayElements<HcpCardInfo>(randomOrderedHcpCardList, 0, firstHcpIndex);
    }
    return randomOrderedHcpCardList;
  };

  return (
    <WebviewLayout hideThemeChanger skipMessengerExtensionLoad>
      <GradientBackground level={0}>
        <StartPageWrapper
          hcp={shuffleHcpList()}
          appointmentInfo={data}
          getAppointmentError={error}
          getAppointmentLoading={loading}
          advertisement={advertisement}
          onGetMoreAppointmentsClick={handleGetMoreAppointmentsClick}
          onMoreInfoAboutNextAppointmentClick={handleMoreInfoAboutNextAppointmentClick}
          onMoreInfoAboutSchedulingClick={handleMoreInfoAboutSchedulingClick}
          onScheduleClick={handleScheduleClick}
          onViewProfileClick={handleViewProfileClick}
        />
      </GradientBackground>
    </WebviewLayout>
  );
};

export default InicioPage;

function useInicioPageAdvertisement(): IAdvertisementSectionProps {
  const [advertisementInfo, setAdvertisementInfo] = useState<{ title: string; description: string; callToAction: string }>(null);
  const advertisement: IAdvertisementSectionProps = advertisementInfo && {
    ...advertisementInfo,
    onClick: () => {
      const msg: CustomWebviewMessageForTeleatendimentoInicio = {
        type: 'custom',
        id: 'ad_click',
      };
      NativeHelper.postMessage(msg);
    },
  };
  useEffect(() => {
    const { ad_call_to_action, ad_description, ad_title } = (NativeHelper.extraData as NativeConfigWebviewExtraDataTelehealthInfo) ?? {};
    setAdvertisementInfo({ title: ad_title, callToAction: ad_call_to_action, description: ad_description });
  }, [NativeHelper.extraData]);
  return advertisement;
}

export const query = graphql`
  query HcpInfoQuery {
    hasura {
      health_carer_telehealth(where: { active: { _eq: true } }) {
        dav_id
        approaches
        education
        profile_url
        themes
        description
        sort_weight
        health_carer {
          id
          name
          professional_id
          professional_id_state
        }
      }
    }
  }
`;
